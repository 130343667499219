import React, { useState, useEffect } from 'react'
import ExistingCardsAccordion from './ExistingCardAccordion'
import {
    Select,
    MenuItem,
    TextField,
    Button,
    Alert,
    Snackbar,
    FormGroup,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
    FormLabel,
    FormControl,
    Grid,
} from '@mui/material'
import axios from 'axios'
import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const types = [
    { id: 0, title: 'Regel', backgroundColor: '#517DE5' },
    { id: 1, title: 'Pekelek', backgroundColor: '#F6CB4A' },
    { id: 2, title: 'Jeg har aldri...', backgroundColor: '#F94383' },
    { id: 3, title: 'Tommel Opp/Ned', backgroundColor: '#56D65A' },
    { id: 4, title: 'Kategorien er...', backgroundColor: '#B453BD' },
    { id: 5, title: 'Utfordring', backgroundColor: '#ED3B3B' },
    { id: 6, title: 'Tilfeldig Event', backgroundColor: '#70AE6E' },
    { id: 7, title: 'Nødt eller Sannhet', backgroundColor: '#D97540' },
    {
        id: 8,
        title: 'Dealbreaker, Red Flag eller Ok',
        backgroundColor: '#715FCD',
    },
    { id: 9, title: 'Gjør eller Drikk', backgroundColor: '#61CAF4' },
    { id: 12, title: 'Drinking Buddy', backgroundColor: '#DEB12C' },
    { id: 13, title: 'TØRST!', backgroundColor: '#DEB12C' },
]

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            text: 'Antall kort etter kategori',
        },
    },
}

const AddCards = () => {
    const [type, setType] = useState(0)
    const [text, setText] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [gamemodes, setGamemodes] = useState({})
    const [state, setState] = useState({})
    const [punishment, setPunishment] = useState('1slurk')
    const [punishmentText, setPunishmentText] = useState('')
    const [equipment, setEquipment] = useState([])

    const [existingCards, setExistingCards] = useState([])

    const [data, setData] = useState(null)

    const [chartFilterGamemode, setChartFilterGamemode] = useState('Ingen')

    useEffect(() => {
        const fetchData = async () => {
            const gamemodes = await axios.get(
                'https://api.toerst.no/api/gamemodes'
            )
            const gamemodesData = gamemodes.data
            const endGamemodes = {}
            const endMapping = {}
            gamemodesData.games
                .filter((item) => item.cardsAddable)
                .forEach((game) => {
                    endGamemodes[game.name] = false
                    endMapping[game.name] = game.id
                })
            setState(endGamemodes)
            setGamemodes(endMapping)

            // * Get stats
            const gamemode =
                chartFilterGamemode === '' || chartFilterGamemode === 'Ingen'
                    ? ''
                    : endMapping[chartFilterGamemode]
            const page = await axios.get(
                `https://api.toerst.no/api/gamecards/stats?gamemode=${gamemode}`
            )

            const pageData = page.data
            setData({
                labels: Object.keys(pageData.stats).map(
                    (key) =>
                        types.find((type) => type.id === parseInt(key)).title
                ),
                datasets: [
                    {
                        label: 'Antall etter kategorier',
                        backgroundColor: 'red',
                        data: Object.values(pageData.stats),
                    },
                ],
            })

            // * Get equipment
            const equipmentPage = await axios.get(
                'https://api.toerst.no/api/equipment'
            )
            const eq = equipmentPage.data
            eq.forEach((item) => {
                item.checked = false
            })
            setEquipment(eq)
        }
        fetchData()
    }, [chartFilterGamemode])

    useEffect(() => {
        async function fetchData() {
            const page = await axios.get(
                `https://api.toerst.no/api/gamecards/info?category=${type}/`
            )
            const parsed = page.data
            setExistingCards(parsed)
        }
        fetchData()
    }, [type])

    const sendCard = async () => {
        const selectedGamemodes = []

        Object.keys(state).forEach((key) => {
            if (state[key]) selectedGamemodes.push(gamemodes[key])
        })

        const selectedEquipment = []
        equipment.forEach((eq) => {
            if (eq.checked) selectedEquipment.push(eq.id)
        })

        const mapPunishment = {
            '1slurk': { drinks: 1, shots: 0, text: punishmentText },
            '2slurker': { drinks: 2, shots: 0, text: punishmentText },
            '3slurker': { drinks: 3, shots: 0, text: punishmentText },
            '1shot': { drinks: 0, shots: 1, text: punishmentText },
            '2shots': { drinks: 0, shots: 2, text: punishmentText },
        }

        const card = {
            category: type,
            text,
            punishment: mapPunishment[punishment],
            gamemodes: selectedGamemodes,
            equipment: selectedEquipment,
        }

        await axios.post('https://api.toerst.no/api/gamecards', card)
        resetSelectedGamemodes()
        resetSelectedEquipment()
        setOpenSnackbar(true)
        setPunishmentText('')
        setText('')
    }

    const resetSelectedGamemodes = () => {
        const updatedDictionaryState = {}

        for (const key in state) {
            if (state.hasOwnProperty(key)) {
                updatedDictionaryState[key] = false
            }
        }

        setState(updatedDictionaryState)
    }

    const resetSelectedEquipment = () => {
        const updatedEquipment = equipment.map((item) => ({
            ...item,
            checked: false,
        }))
        setEquipment(updatedEquipment)
    }

    const updateSelectedGamemodes = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        })
    }

    const handlePunishmentChange = (event) => {
        setPunishment(event.target.value)
    }

    const handleChartFilterGamemodeChange = (event) => {
        setChartFilterGamemode(event.target.value)
    }

    const toggleEquipment = (index) => {
        const updatedEquipment = [...equipment]
        updatedEquipment[index].checked = !updatedEquipment[index].checked

        setEquipment(updatedEquipment)
    }

    if (data === null) return null

    return (
        <>
            <Grid container maxWidth="xl" marginLeft="auto" marginRight="auto">
                <Grid item md={6}>
                    <h2>Nytt Tørst spørsmål</h2>
                    <Grid item md={12} sx={{ marginRight: '1rem' }}>
                        <Select
                            label="Kategori"
                            value={type}
                            onChange={(event) => setType(event.target.value)}
                            required
                        >
                            {types.map((type, index) => (
                                <MenuItem value={type.id} key={index}>
                                    {type.title}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            label="Tekst"
                            required
                            variant="outlined"
                            autoComplete="false"
                            value={text}
                            onChange={(event) => setText(event.target.value)}
                        />
                    </Grid>
                    <Grid item md={12} sx={{ marginTop: '1rem' }}>
                        <FormControl>
                            <FormLabel>Gamemodes*</FormLabel>
                            <FormGroup row>
                                {Object.keys(state).map((key) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state[key]}
                                                onChange={() =>
                                                    updateSelectedGamemodes({
                                                        target: {
                                                            name: key,
                                                            checked:
                                                                !state[key],
                                                        },
                                                    })
                                                }
                                            />
                                        }
                                        label={key}
                                        key={key}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} sx={{ marginTop: '1rem' }}>
                        <FormControl>
                            <FormLabel>Equipment*</FormLabel>
                            <FormGroup row>
                                {equipment.map((eq, index) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={eq.checked}
                                                onChange={() =>
                                                    toggleEquipment(index)
                                                }
                                            />
                                        }
                                        label={eq.equipment}
                                        key={eq.equipment}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} sx={{ marginTop: '1rem' }}>
                        <FormControl>
                            <FormLabel>Straff*</FormLabel>
                            <RadioGroup
                                defaultValue="1slurk"
                                row
                                onChange={handlePunishmentChange}
                                value={punishment}
                            >
                                <FormControlLabel
                                    value="1slurk"
                                    control={<Radio />}
                                    label="1 Slurk"
                                />
                                <FormControlLabel
                                    value="2slurker"
                                    control={<Radio />}
                                    label="2 Slurker"
                                />
                                <FormControlLabel
                                    value="3slurker"
                                    control={<Radio />}
                                    label="3 Slurker"
                                />
                                <FormControlLabel
                                    value="1shot"
                                    control={<Radio />}
                                    label="1 Shot"
                                />
                                <FormControlLabel
                                    value="2shots"
                                    control={<Radio />}
                                    label="2 Shots"
                                />
                            </RadioGroup>
                            <TextField
                                label="Straffetekst"
                                variant="outlined"
                                value={punishmentText}
                                onChange={(event) =>
                                    setPunishmentText(event.target.value)
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} sx={{ marginTop: '1rem' }}>
                        <Button
                            variant="contained"
                            onClick={sendCard}
                            size="large"
                        >
                            Send Inn
                        </Button>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <ExistingCardsAccordion
                        existingCards={existingCards}
                        setExistingCards={setExistingCards}
                        state={state}
                        gamemodes={gamemodes}
                        equipment={equipment}
                        text={text}
                    />
                </Grid>
            </Grid>

            <div
                style={{
                    width: '50vw',
                    heigth: 'auto',
                    backgroundColor: types.filter((item) => item.id === type)[0]
                        .backgroundColor,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <h2>{types.filter((item) => item.id === type)[0].title}</h2>
                <h3 style={{ marginTop: '-1rem', fontWeight: 'normal' }}>
                    {text}
                </h3>
            </div>

            <Bar options={options} data={data} style={{ maxHeight: '400px' }} />

            <FormControl>
                <FormLabel>Gamemode Filter</FormLabel>
                <RadioGroup
                    row
                    onChange={handleChartFilterGamemodeChange}
                    value={chartFilterGamemode}
                >
                    <FormControlLabel
                        control={<Radio />}
                        value="Ingen"
                        label="Ingen"
                    />
                    {Object.keys(state).map((key) => (
                        <FormControlLabel
                            control={<Radio />}
                            value={key}
                            label={key}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <Snackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert variant="filled" severity="success">
                    The card has been submitted!
                </Alert>
            </Snackbar>
        </>
    )
}
export default AddCards
