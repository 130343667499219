import React, { useEffect, useState } from 'react'
import { TextField, Snackbar, Alert, Button, Stack } from '@mui/material'
import axios from 'axios'

const AddSongs = () => {
    const [startSec, setStartSec] = useState(0)
    const [spotifyId, setSpotifyId] = useState(null)
    const [youtubeLink, setYoutubeLink] = useState('')
    const [artists, setArtists] = useState(null)
    const [title, setTitle] = useState(null)

    useEffect(() => {
        console.log(youtubeLink?.replace('watch?v=', 'embed/'))
    }, [youtubeLink])

    const [error, setError] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)

    const submit = async () => {
        axios
            .post('https://api.toerst.no/api/songcards/uncut', {
                author: artists,
                name: title,
                youtubeLink,
                startS: startSec,
                spotify_id: spotifyId,
            })
            .then(() => {
                setStartSec(0)
                setSpotifyId(null)
                setYoutubeLink('')
                setArtists(null)
                setTitle(null)

                setError(false)
                setOpenSnackbar(true)
            })
            .catch((e) => {
                console.error(e)
                setError(true)
                setOpenSnackbar(true)
            })
    }

    return (
        <>
            <Stack
                direction="column"
                spacing={1.5}
                width={'26rem'}
                marginLeft={'auto'}
                marginRight={'auto'}
                marginTop={'1rem'}
                marginBottom={'1rem'}
            >
                <TextField
                    variant="outlined"
                    label="Start Sec"
                    type="number"
                    value={startSec}
                    error={startSec < 0}
                    helperText={startSec < 0 ? 'Must be a positive number' : ''}
                    required
                    onChange={(e) => setStartSec(e.target.value)}
                />
                <TextField
                    value={spotifyId}
                    variant="outlined"
                    label="Spotify ID"
                    type="text"
                    required
                    onChange={(e) => setSpotifyId(e.target.value)}
                />
                <TextField
                    value={youtubeLink}
                    variant="outlined"
                    label="YouTube Link"
                    type="text"
                    required
                    onChange={(e) => setYoutubeLink(e.target.value)}
                />
                <TextField
                    value={artists}
                    variant="outlined"
                    label="Artists"
                    type="text"
                    required
                    onChange={(e) => setArtists(e.target.value)}
                />
                <TextField
                    value={title}
                    variant="outlined"
                    label="Title"
                    type="text"
                    required
                    onChange={(e) => setTitle(e.target.value)}
                />

                <iframe
                    title="YouTube Preview"
                    style={{ width: '100%', aspectRatio: '16/9' }}
                    src={youtubeLink?.replace('watch?v=', 'embed/')}
                />

                <Button variant="contained" onClick={submit}>
                    Submit
                </Button>
            </Stack>

            <Snackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert variant="filled" severity={error ? 'error' : 'success'}>
                    {error
                        ? 'Something went wrong'
                        : 'The song has been submitted!'}
                </Alert>
            </Snackbar>
        </>
    )
}
export default AddSongs
