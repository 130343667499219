import React, { useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    FormControl,
    FormGroup,
    Checkbox,
    FormLabel,
    FormControlLabel,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import axios from 'axios'

const ExistingCardsAccordion = ({
    existingCards,
    setExistingCards,
    state,
    gamemodes,
    equipment,
    text,
}) => {
    const [expanded, setExpanded] = useState(false)

    const updateCard = async (cardId, changes) => {
        const res = await axios.post(
            'https://api.toerst.no/api/gamecards/update',
            {
                cardId,
                changes,
            }
        )
        if (res.status === 200) {
            const updatedCards = existingCards.map((card) => {
                if (card.id === cardId) {
                    return { ...card, ...changes }
                }
                return card
            })

            setExistingCards(updatedCards)
        }
    }

    const handleChange = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <>
            <h2>Eksisterende Innenfor Kategori</h2>
            <div style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                {existingCards
                    .filter((item) => item.text.includes(text))
                    .map((card) => (
                        <Accordion
                            expanded={expanded === card.id}
                            onChange={handleChange(card.id)}
                            key={card.id}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography style={{ textAlign: 'left' }}>
                                    {card.text}
                                </Typography>
                            </AccordionSummary>
                            {expanded === card.id && (
                                <AccordionDetails>
                                    <FormControl>
                                        <FormLabel>Gamemodes</FormLabel>
                                        <FormGroup row>
                                            {Object.keys(state).map((key) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={card.gamemodes.includes(
                                                                gamemodes[key]
                                                            )}
                                                            onChange={() => {
                                                                const existingGamemodes =
                                                                    [
                                                                        ...card.gamemodes,
                                                                    ]
                                                                if (
                                                                    existingGamemodes.includes(
                                                                        gamemodes[
                                                                            key
                                                                        ]
                                                                    )
                                                                ) {
                                                                    existingGamemodes.splice(
                                                                        existingGamemodes.indexOf(
                                                                            gamemodes[
                                                                                key
                                                                            ]
                                                                        ),
                                                                        1
                                                                    )
                                                                } else {
                                                                    existingGamemodes.push(
                                                                        gamemodes[
                                                                            key
                                                                        ]
                                                                    )
                                                                }
                                                                updateCard(
                                                                    card.id,
                                                                    {
                                                                        gamemodes:
                                                                            existingGamemodes,
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                    }
                                                    label={key}
                                                    key={key}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                </AccordionDetails>
                            )}
                            {expanded === card.id && (
                                <AccordionDetails>
                                    <FormControl>
                                        <FormLabel>Equipment</FormLabel>
                                        <FormGroup row>
                                            {equipment.map((eq) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={card.equipment.includes(
                                                                eq.id
                                                            )}
                                                            onChange={() => {
                                                                const existingEquipment =
                                                                    [
                                                                        ...card.equipment,
                                                                    ]
                                                                if (
                                                                    existingEquipment.includes(
                                                                        eq.id
                                                                    )
                                                                ) {
                                                                    existingEquipment.splice(
                                                                        existingEquipment.indexOf(
                                                                            eq.id
                                                                        ),
                                                                        1
                                                                    )
                                                                } else {
                                                                    existingEquipment.push(
                                                                        eq.id
                                                                    )
                                                                }
                                                                updateCard(
                                                                    card.id,
                                                                    {
                                                                        equipment:
                                                                            existingEquipment,
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                    }
                                                    label={eq.equipment}
                                                    key={eq.equipment}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                </AccordionDetails>
                            )}
                        </Accordion>
                    ))}
            </div>
        </>
    )
}

export default ExistingCardsAccordion
